import React from 'react'

import SEO from "../../components/atoms/seo"
// import Confetti from '../../components/atoms/confetti'
import Layout from '../../components/organisms/layout'
import Title from '../../components/atoms/title'
import Lists from '../../components/atoms/lists'
import BodyCopy from '../../components/atoms/body-copy'
// import Callout from '../../components/atoms/callout'

import content from "../../data/pages/es/thank-you/content.json"

const ThankYouPage = () => (
  <Layout content={content.layout}>
    <SEO title={content.seo.title} />
    {/* <Confetti /> */}
    <div className='p-thank-you'>
      <div className='l-section u-text-center'>
        <Title
            type='h1'
            title={content.title}
            classNames='p-thank-you__title'
        />
        <Title
            type='h4'
            title={content.subTitle}
            classNames='p-thank-you__title p-thank-you__title--2 l-spacing__m-b--40'
        />
        <Title
            type='h2'
            title={content.secondaryTitle}
            classNames='p-thank-you__title'
        />
      </div>
      <div className='l-section'>
        <Title
          type='h5'
          title={content.listTitle}
          classNames='p-thank-you__title p-thank-you__title--1 l-spacing__m-b--32'
        />
        <Lists
          items={content.list}
          classNames='p-thank-you__list-checkmark l-spacing__p-b--8'
          checkmark
        />
        <BodyCopy
          copy={content.footnote}
          classNames='p-thank-you__copy'
          footnote
        />
      </div>
      <div className='l-section'>
        {/* <Callout
          title={content.callout.title}
          message={content.callout.message}
        /> */}
      </div>
    </div>
  </Layout>
)

export default ThankYouPage
